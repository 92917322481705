<template>
  <div>
    <SearchInput
      @result="search"
      @close="() => $emit('close')"
    />
  </div>
</template>

<script>
  import SearchInput from '@/components/utils/SearchInput'
  import { searchArtists } from '@/api/artists'

  export default {
    components: {
      SearchInput
    },
    methods: {
      async search(query) {
        const artists = await searchArtists(query, {
          queryParams: {with: 'genre'}
        })

        this.$emit('result', artists)
      }
    }
  }
</script>