import { render, staticRenderFns } from "./artists-index.vue?vue&type=template&id=56f246bc&scoped=true&"
import script from "./artists-index.vue?vue&type=script&lang=js&"
export * from "./artists-index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56f246bc",
  null
  
)

export default component.exports