<template>
  <div class="page-with-title">
    <page-title>
      <div class="row">
        <div class="col-3">
          <h1>Artistas</h1>
        </div>
        <div class="col-9">
          <ArtistSearchInput
            @result="showSearchResults"
            @close="hideSearchResults"
          />
        </div>
      </div>
    </page-title>
    <div class="p-4">
      <div class="row justify-content-center">
        <div class="col-8">
          <table class="table table-dark">
            <thead>
              <tr>
                <th>ID</th>
                <th>-</th>
                <th>Artista</th>
                <th>Gênero</th>
                <th>-</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="artist in artists"
                :key="artist.id_artista"
              >
                <td>{{ artist.id_artista }}</td>
                <td width="30">
                  <img
                    :src="artist.avatar"
                    width="30"
                    height="30"
                  >
                </td>
                <td>
                  <router-link :to="{ name: 'artistShow', params: {id: artist.cod_artista} }">
                    {{ artist.artista }}
                  </router-link>
                </td>
                <td>
                  <strong
                    v-if="artist.genre"
                  >
                    {{ artist.genre.nome }}
                  </strong>
                  <i v-else>Sem gênero</i>
                </td>
                <td>
                  <div class="dropdown">
                    <button
                      class="btn btn-sm btn-secondary dropdown-toggle"
                      type="button"
                      data-toggle="dropdown"
                    >
                      -
                    </button>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a
                        :href="artist.url"
                        target="_blank"
                        class="dropdown-item"
                      >
                        <i class="fa fa-external-link" /> Acessar artista no site
                      </a>
                      <a
                        v-if="artist.genre"
                        :href="artist.genre.url"
                        target="_blank"
                        class="dropdown-item"
                      >
                        <i class="fa fa-external-link" /> Acessar gênero no site
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div
      v-if="!oldArtists.length"
      class="text-center mb-4"
    >
      <button-next-page
        @reached="page++; getArtists()"
      />
    </div>
  </div>
</template>

<script>
  import PageTitle from '@/components/titles/PageTitle'
  import ButtonNextPage from '@/components/utils/ButtonNextPage'
  import ArtistSearchInput from '@/components/artists/ArtistSearchInput'
  import { getArtists } from '@/api/artists'

  export default {
    components: {
      PageTitle,
      ArtistSearchInput,
      ButtonNextPage
    },
    data: () => ({
      artists: [],
      oldArtists: [],
      page: 1,
      lastPage: null
    }),
    created() {
      this.getArtists()
    },
    methods: {
      async getArtists() {
        this.$store.commit('app/SET_LOADING', true)
        const res = await getArtists({ page: this.page })
        res.data.data.data.map(s => this.artists.push(s))
        this.$store.commit('app/SET_LOADING', false)
      },
      showSearchResults(searchArtists) {
        if (!this.oldArtists.length) {
          this.oldArtists = this.artists
        }
        this.artists = searchArtists
      },
      hideSearchResults() {
        this.artists = this.oldArtists
        this.oldArtists = []
      }
    }
  }
</script>

<style scoped lang="scss">
  
</style>
